import {
  CLOUD_RECORDINGS_FREQUENCIES,
  CLOUD_RECORDINGS_STATUSES,
  CLOUD_RECORDINGS_STORAGE_DURATION,
} from "@/components/constants.js"

export default ({ $config = {}, getStatusText }) => {
  return [
    {
      value: "name",
      text: "Name",
      sortable: true,
      filterable: true,
      width: 160,
    },
    {
      value: "status",
      text: "Status",
      sortable: true,
      filterable: true,
      width: 160,
      align: "center",
      toStringFn: (item, key) => getStatusText(item[key]),
    },
    {
      value: "exid",
      text: "ID",
      sortable: true,
      filterable: true,
      width: 90,
      cellClass: "truncate",
    },
    {
      value: "zohoId",
      text: "Zoho",
      sortable: false,
      filterable: false,
      width: 40,
      align: "center",
      toStringFn: (item, key) =>
        item[key] &&
        `${$config.public.zohoCrmUrl}/tab/CustomModule4/${item[key]}`,
    },
    {
      value: "demo",
      text: "Demo",
      sortable: false,
      filterable: false,
      width: 40,
      align: "center",
    },
    {
      value: "userFullname",
      text: "Owner",
      sortable: true,
      width: 160,
      filterable: true,
    },
    {
      value: "userEmail",
      text: "Email",
      width: 160,
      sortable: true,
      filterable: true,
      visible: false,
      cellClass: "truncate",
    },
    {
      value: "projectName",
      text: "Project",
      sortable: true,
      filterable: true,
      width: 160,
    },
    {
      value: "createdAt",
      text: "Created At",
      sortable: true,
      filterable: true,
      width: 135,
    },
    {
      value: "featureFlags",
      text: "Feature Flags",
      sortable: true,
      width: 200,
    },
    {
      value: "lastOnlineAt",
      text: "Last Online At",
      sortable: true,
      width: 135,
      filterable: true,
      visible: false,
    },
    {
      value: "cameraHost",
      text: "Camera IP",
      sortable: true,
      filterable: true,
      width: 165,
    },
    {
      value: "cameraHttpPort",
      text: "Camera Port",
      sortable: true,
      filterable: true,
      width: 52,
      align: "center",
    },
    {
      value: "nvrHttpPort",
      text: "NVR Port",
      sortable: true,
      filterable: true,
      width: 52,
      align: "center",
    },
    {
      value: "nvrRtspPort",
      text: "RTSP Port",
      sortable: true,
      filterable: true,
      width: 52,
      visible: false,
      align: "center",
    },
    {
      value: "deviceId",
      text: "Device ID",
      sortable: true,
      filterable: true,
      width: 32,
    },
    {
      value: "nvrModel",
      text: "NVR Model",
      sortable: true,
      filterable: true,
      width: 94,
    },
    {
      value: "cameraUsername",
      text: "Username",
      sortable: true,
      filterable: true,
      visible: false,
      width: 81,
    },
    {
      value: "cameraPassword",
      text: "Password",
      sortable: true,
      filterable: true,
      visible: false,
      width: 119,
    },
    {
      value: "recordingFromNvr",
      text: "Recording NVR",
      sortable: true,
      filterable: true,
      width: 60,
    },
    {
      value: "cameraModel",
      text: "Camera Model",
      sortable: true,
      filterable: true,
      width: 94,
    },
    {
      value: "timezone",
      text: "Timezone",
      sortable: true,
      filterable: true,
      width: 136,
    },
    {
      value: "region",
      text: "Region",
      sortable: true,
      filterable: true,
      width: 70,
      align: "center",
    },
    {
      value: "isPublic",
      text: "Public",
      sortable: true,
      filterable: true,
      width: 60,
      visible: false,
      align: "center",
    },
    {
      value: "cloudRecordingStorageDuration",
      text: "Duration",
      sortable: true,
      filterable: true,
      width: 82,
      align: "center",
      toStringFn: (item, key) => parseCloudRecordingStorageDurations(item[key]),
    },
    {
      value: "cloudRecordingStatus",
      text: "CR Status",
      sortable: true,
      filterable: true,
      width: 79,
      align: "center",
      toStringFn: (item, key) => parseCRStatus(item[key]),
    },
    {
      value: "cloudRecordingFrequency",
      text: "Frequency",
      sortable: true,
      filterable: true,
      width: 148,
      align: "center",
      toStringFn: (item, key) => parseFrequency(item[key]),
    },
    {
      value: "lastPolledAt",
      text: "Last Polled At",
      sortable: true,
      width: 200,
      filterable: true,
      visible: false,
    },
    {
      value: "location",
      text: "Location",
      sortable: true,
      width: 309,
      filterable: true,
      toStringFn: (item, key) =>
        item[key] ? `lat: ${item[key].lat} / lng: ${item[key].lng}` : "-",
    },
  ]
}

export const parseFrequency = (value) => {
  return CLOUD_RECORDINGS_FREQUENCIES.find(
    (frequency) => frequency.value == value
  )?.name
}

export const parseCRStatus = (value) => {
  return CLOUD_RECORDINGS_STATUSES.find((status) => status.value === value)
    ?.name
}

export const parseCloudRecordingStorageDurations = (value) => {
  return CLOUD_RECORDINGS_STORAGE_DURATION.find((item) => item.value === value)
    ?.name
}
